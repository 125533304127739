import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Installation" />
    <h1>Hello renovators, finishing carpenters and home handy persons!</h1>
    <p>Welcome to SIMTRIM Molding Systems Ltd., a unique and simple method of installing molding and trim, all without having nails or brads penetrating the front face of molding or trim.</p>
  </Layout>
)

export default SecondPage
